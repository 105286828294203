// @optimization
@mixin while-transition() {
  .Transition_slide:not(.Transition_slide-active) & {
    @content;
  }
}

@mixin adapt-padding-to-scrollbar($padding) {
  padding-inline-end: calc($padding - var(--scrollbar-width));
}

@mixin adapt-margin-to-scrollbar($margin) {
  margin-inline-end: calc($margin - var(--scrollbar-width));
}

@mixin filter-outline($width: 0.125rem, $color) {
  filter:
    drop-shadow($width $width 0 $color)
    drop-shadow((-$width) $width 0 $color)
    drop-shadow($width (-$width) 0 $color)
    drop-shadow((-$width) (-$width) 0 $color);
}

@mixin gradient-border-top($width, $cutout: 0px) {
  mask-image: linear-gradient(transparent $cutout, black $width);
}

@mixin gradient-border-bottom($height, $cutout: 0px) {
  mask-image: linear-gradient(to top, transparent $cutout, black $height);
}

@mixin gradient-border-horizontal($borderStart, $borderEnd) {
  mask-image: linear-gradient(to right, transparent, black $borderStart, black calc(100% - $borderEnd), transparent);
}

@mixin gradient-border-left($indent, $cutout: 0px) {
  mask-image: linear-gradient(to right, transparent $cutout, black $indent);
}

@mixin gradient-border-right($indent, $cutout: 0px) {
  mask-image: linear-gradient(to left, transparent $cutout, black $indent);
}

@mixin gradient-border-top-bottom($top, $bottom) {
  mask-image: linear-gradient(transparent 0%, black $top, black calc(100% - $bottom), transparent 100%);
}

@mixin peer-gradient($property, $colorCount) {
  --_accent-color-rgb: var(--color-accent-own-rgb);

  html.theme-dark {
    --_accent-color-rgb: var(--color-text-rgb);
  }

  @if $colorCount == 2 {
    #{$property}:
      repeating-linear-gradient(
        -45deg,
        rgb(var(--_accent-color-rgb), 100%),
        rgb(var(--_accent-color-rgb), 100%) 5px,
        rgb(var(--_accent-color-rgb), 35%) 5px,
        rgb(var(--_accent-color-rgb), 35%) 10px
      );
  }

  @else {
    #{$property}:
      repeating-linear-gradient(
        -45deg,
        rgb(var(--_accent-color-rgb), 100%),
        rgb(var(--_accent-color-rgb), 100%) 5px,
        rgb(var(--_accent-color-rgb), 60%) 5px,
        rgb(var(--_accent-color-rgb), 60%) 10px,
        rgb(var(--_accent-color-rgb), 20%) 10px,
        rgb(var(--_accent-color-rgb), 20%) 15px
      );
  }
}

@mixin reset-range() {
  input[type="range"] {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    height: 0.75rem;
    margin-bottom: 0.5rem;
    background: transparent;

    &:focus {
      outline: none;
    }

    &::-ms-track {
      width: 100%;
      cursor: var(--custom-cursor, pointer);

      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }

    &::-moz-range-thumb {
      -moz-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      cursor: var(--custom-cursor, pointer);
    }

    &::-moz-range-track, &::-moz-range-progress {
      cursor: var(--custom-cursor, pointer);
    }
  }
}

@mixin header-pane {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2.875rem;
  transform: translateY(-100%);
  transition: transform var(--slide-transition);

  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: max(0.75rem, env(safe-area-inset-left));
  padding-right: max(0.5rem, env(safe-area-inset-right));
  background-color: var(--color-background);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -0.1875rem;
    left: 0;
    right: 0;
    height: 0.125rem;
    box-shadow: 0 0.125rem 0.125rem var(--color-light-shadow);
  }

  // Some panels might unmount without animation, so we provide same background above panel to make it less noticeable
  &::after {
    content: "";
    position: absolute;
    top: -100%;
    left: 0;
    right: 0;
    height: inherit;
    background-color: inherit;
    z-index: -1;
  }
}

@mixin side-panel-section {
  background-color: var(--color-background);
  box-shadow: inset 0 -0.0625rem 0 0 var(--color-background-secondary-accent);
  border-bottom: 0.625rem solid var(--color-background-secondary);

  &:last-child {
    border-bottom: none;
    box-shadow: none;
  }
}
