.Checkbox {
  position: relative;
  min-height: 3rem;

  display: flex;
  align-items: center;

  padding-inline-start: 4.25rem;
  text-align: left;
  cursor: var(--custom-cursor, pointer);
  margin-bottom: 0;

  &:hover,
  &:focus-visible {
    background-color: var(--color-chat-hover);
    border-radius: var(--border-radius-default);
  }

  &.disabled {
    cursor: var(--custom-cursor, default);
    opacity: 0.5;

    &:hover {
      background: none !important;
    }
  }

  &.round {
    .Checkbox-main {
      &::before, &::after {
        border-radius: 50%;
      }
    }
  }

  &.withIcon {
    padding-inline-start: 1rem;

    .Checkbox-main {
      &::before,
      &::after {
        left: auto;
        right: 1.1875rem;
      }
    }
  }

  &.loading {
    .Checkbox-main::before,
    .Checkbox-main::after {
      opacity: 0 !important;
    }

    .Spinner {
      position: absolute;
      left: 0.375rem;
      top: 0.125rem;
      opacity: 0;
      animation: fade-in 0.2s ease forwards;
      --spinner-size: 1.25rem;
    }
  }

  &.blocking {
    input:not(:checked) ~ .Checkbox-main {
      &::before {
        border-color: var(--color-error);
      }

      &::after {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgPHBvbHlnb24gcG9pbnRzPSIwIDAgMjQgMCAyNCAyNCAwIDI0Ii8+CiAgICA8cGF0aCBmaWxsPSIjZTUzOTM1IiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xOCwzIEMxOS42NTY4NTQyLDMgMjEsNC4zNDMxNDU3NSAyMSw2IEwyMSwxOCBDMjEsMTkuNjU2ODU0MiAxOS42NTY4NTQyLDIxIDE4LDIxIEw2LDIxIEM0LjM0MzE0NTc1LDIxIDMsMTkuNjU2ODU0MiAzLDE4IEwzLDYgQzMsNC4zNDMxNDU3NSA0LjM0MzE0NTc1LDMgNiwzIEwxOCwzIFogTTE2LDExIEw4LDExIEM3LjQ0NzcxNTI1LDExIDcsMTEuNDQ3NzE1MyA3LDEyIEM3LDEyLjU1MjI4NDcgNy40NDc3MTUyNSwxMyA4LDEzIEwxNiwxMyBDMTYuNTUyMjg0NywxMyAxNywxMi41NTIyODQ3IDE3LDEyIEMxNywxMS40NDc3MTUzIDE2LjU1MjI4NDcsMTEgMTYsMTEgWiIvPgogIDwvZz4KPC9zdmc+Cg==);
        opacity: 1;

        // Compensate for svg having incorrect size
        background-size: 1.5rem;
        background-position: -0.125rem -0.125rem;
      }
    }
  }

  input {
    position: absolute;
    z-index: var(--z-below);
    opacity: 0;
  }

  .Checkbox-main {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;
    flex-grow: 1;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;
      left: 1.125rem; // 1 + ((1.5 - 1.25) / 2)
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    &::before {
      border: 0.125rem solid var(--color-borders-input);
      border-radius: 0.25rem;
      background-color: var(--color-background);
      transition: border-color 0.1s ease, background-color 0.1s ease;
    }

    &::after {
      /* stylelint-disable-next-line scss/operator-no-unspaced */
      background: center no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjkuOEw1LjggOC45IDIuMSA1LjJjLS40LS40LTEuMS0uNC0xLjYgMC0uNC40LS40IDEuMSAwIDEuNkw1IDExLjJjLjQuNCAxLjEuNCAxLjYgMGw4LjktOC45Yy40LS40LjQtMS4xIDAtMS42LS41LS40LTEuMi0uNC0xLjYuMXoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
      background-size: 0.875rem;
      opacity: 0;
      transition: opacity 0.1s ease;
    }

    .label {
      word-break: break-word;
      unicode-bidi: plaintext;
      text-align: left;
      line-height: 1.25rem;
    }

    .right-icon {
      grid-column: 2;
      grid-row: span 2;
      margin-left: auto;
      margin-right: 0.5rem;
      color: var(--color-text-secondary);
      font-size: 1.25rem;
      align-self: center;
    }

    .subLabel {
      grid-column: 1;
      grid-row: 2;
      font-size: 0.875rem;
      line-height: 1rem;
      color: var(--color-text-secondary);
      unicode-bidi: plaintext;
      margin-top: 0.25rem;
    }
  }

  &.withSubLabel {
    &:hover,
    &:focus-visible {
      background-color: var(--color-chat-hover);
      border-radius: var(--border-radius-default);
    }

    .Checkbox-main {
      padding: 0.4375rem 0 0.5625rem 0;
    }
  }

  .Nested-avatar-list {
    &::before,
    &::after {
      pointer-events: none;
      content: "";
      display: block;
      position: absolute;
      left: 1.125rem;
      width: 1.125rem;
      height: 1.125rem;
    }

    .label {
      display: flex;
      align-items: center;
      column-gap: 0.6875rem;
    }
  }

  input:checked ~ .Checkbox-main {
    &::before {
      border-color: var(--color-primary);
      background-color: var(--color-primary);
    }

    &::after {
      opacity: 1;
    }
  }

  &[dir="rtl"] {
    &.loading {
      .Spinner {
        left: auto;
        right: 0.375rem;
      }
    }

    .label,
    .subLabel {
      text-align: right;
    }

    .Checkbox-main {
      &::before,
      &::after {
        left: auto;
        right: 1rem;
      }
    }
  }

  &.nested {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.3125rem;
    margin-block: 0;
    padding: 0 0 0 4.1875rem;

    &:hover,
    &:focus-visible {
      background-color: var(--color-chat-hover);
      border-radius: var(--border-radius-default);
    }
  }

  &.withNestedList {
    background-color: var(--color-background);
    margin: 0;

    &:hover,
    &:focus-visible {
      background-color: var(--color-chat-hover);
      border-radius: var(--border-radius-default);
    }
  }

  &.permission-group {
    padding-inline-start: 3.625rem;

    .Checkbox-main {
      &::before,
      &::after {
        left: 1rem;
      }
    }
  }

  &.permission-group.withNestedList {
    margin-bottom: 1rem;
  }

  &.avatar {
    padding-inline-start: 3.5rem;
    margin-inline-start: 3.125rem;
  }

  .button {
    color: var(--color-text);
    display: flex;
    gap: 0.1875rem;
  }

  .group-icon {
    font-size: 0.75rem;
  }

  &.nested-checkbox-group &.Checkbox-main::before,
  &.nested-checkbox-group &.Checkbox-main::after {
    top: 1.875rem;
    left: 2.875rem;
  }

  &.onlyInput {
    min-height: auto;
    position: relative;
    padding-inline-start: 0.8125rem;

    .Checkbox-main {
      &::before,
      &::after {
        left: 0;
        right: 0;
        top: 0;
      }
    }
  }
}

.nested-checkbox-group {
  overflow: hidden;
  max-height: 0;
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: max-height 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.nested-checkbox-group-open {
  max-height: 100vh;
  /* stylelint-disable-next-line plugin/no-low-performance-animation-properties */
  transition: max-height 0.6s ease-in-out;
}
