.Avatar {
  --premium-gradient: linear-gradient(88.39deg, #6C93FF -2.56%, #976FFF 51.27%, #DF69D1 107.39%);
  --color-user: var(--color-primary);
  --radius: 50%;
  --_size: 0px;
  --_font-size: max(calc(var(--_size) / 2 - 2px), 0.5rem);

  flex: none;
  align-items: center;
  justify-content: center;
  width: var(--_size);
  height: var(--_size);
  border-radius: var(--radius);
  color: white;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  user-select: none;
  position: relative;

  font-size: var(--_font-size);

  .emoji {
    width: var(--_font-size);
    height: var(--_font-size);
  }

  &__icon {
    font-size: calc(var(--_size) / 2);
  }

  > .inner {
    overflow: hidden;
    border-radius: var(--radius);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(var(--color-white) -300%, var(--color-user));
  }

  &__media {
    width: 100%;
    height: 100%;
  }

  &.force-fit &__media {
    object-fit: cover;
  }

  &.interactive {
    cursor: var(--custom-cursor, pointer);
  }

  &.with-story-circle {
    z-index: 1;

    > .inner {
      width: calc(100% - 0.375rem);
      height: calc(100% - 0.375rem);
      left: 0.1875rem;
      top: 0.1875rem;
    }
  }

  &.with-story-solid {
    width: calc(var(--_size) - 0.25rem);
    height: calc(var(--_size) - 0.25rem);
    margin: 0.1875rem;

    &::before {
      content: "";
      position: absolute;
      width: calc(var(--_size) + 0.25rem);
      height: calc(var(--_size) + 0.25rem);
      left: -0.25rem;
      top: -0.25rem;
      border-radius: 50%;
      background: var(--color-borders-read-story);
    }

    &::after {
      content: "";
      position: absolute;
      width: var(--_size);
      height: var(--_size);
      left: -0.125rem;
      top: -0.125rem;
      border-radius: 50%;
      z-index: 0;
      background: var(--color-background);
    }

    &.online::after {
      bottom: -0.125rem;
      right: -0.125rem;
    }
  }

  &.has-unread-story::before {
    background-image:
      linear-gradient(
        215.87deg,
        var(--color-avatar-story-unread-from) -1.61%,
        var(--color-avatar-story-unread-to) 97.44%,
      );
  }

  &.has-unread-story.close-friend::before {
    background-image:
      linear-gradient(
        215.87deg,
        var(--color-avatar-story-friend-unread-from) -1.61%,
        var(--color-avatar-story-friend-unread-to) 97.44%,
      );
  }

  .poster {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &.forum {
    --radius: var(--border-radius-forum-avatar);
  }

  &.hidden-user {
    --color-user: var(--color-deleted-account);
  }

  &.premium-gradient-bg > .inner {
    background-image: var(--premium-gradient);
  }
}
